import axios from '@/config/axios'
export default {
    getUnreadCount({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('notifications/unread_count/')
                .then(({ data }) => {
                    if (data) {
                        commit('SET_VALUE_STATE', { name: 'unreadCount', data: data.unread_count })
                        commit("SET_PWA_COUNTER", { name: 'noty', value: data.unread_count }, { root: true })
                    }
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
    getListNoty({ commit, state }, { page_name }) {
        return new Promise((resolve, reject) => {
            commit('SET_PAGE', state.page+1)
            axios.get('notifications/', {
                params: {
                    page_size: 10, 
                    page: state.page,
                    page_name
                }
            })
                .then(({ data }) => {
                    if (data) {
                        commit('SET_NEXT', data.next)
                        commit('SET_LIST_NOTY', data)

                    }
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
    getNotyBell({ commit }, { page, user }) {
        return new Promise((resolve, reject) => {
            axios.get('notifications/', {
                params: {
                    page_size: 10, page,
                    filters:
                    {
                        webnotificationrecipientmodel__is_read: false,
                        webnotificationrecipientmodel__recipient__id: user
                    }
                }
            })
                .then(({ data }) => {
                    if (data) {
                        commit('SET_LIST_NOTY_BELL', data)

                    }
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
    readNoty({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios.post('notifications/is_read/', { notifications: [id] })
                .then(({ data }) => {
                    if (data) {
                        commit("READ_NOTY", { id })

                    }
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
    readAllNoty({ commit }) {
        return new Promise((resolve, reject) => {
            axios.post('notifications/is_read/', { notifications: 'all' })
                .then(({ data }) => {
                    if (data) {
                        commit("READ_NOTY", { id: 'all' })

                    }
                    resolve(data)
                })
                .catch((error) => { reject(error) })
        })
    },
}