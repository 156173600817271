import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
import { generateDeviceUUID } from '@/utils/utils.js'
export default {
    computed: {
        ...mapState({
            config: state => state.config.config
        }),
        user() {
            return this.$store.state.user.user
        }
    },
    methods: {
        async checkNotify() {
            try {
                const status = await Notification.requestPermission()
                if(this.user) {
                    if (status === 'granted') {
                        const reg = await navigator.serviceWorker.ready
                        reg.pushManager.getSubscription()
                            .then(subscription => {
                                if(subscription) {
                                    this.userUpdateSubscribe(subscription)
                                } else
                                    this.userSubscribe(reg)
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                } else {
                    this.userUnregisterSubscribe()
                }
            } catch (error) {
                console.error('Error enabling push notification', error)
            }
        },
        userUnregisterSubscribe() {
            console.log('User not authorized')
        },
        async userUpdateSubscribe(subscription) {
            try {
                const payload = {
                    subscription,
                    uid: this.user.id,
                    buid: generateDeviceUUID()
                }

                const {data} = await this.$http2.post('/subscribe/update/', payload)
                if(data?.data?.auth)
                    this.$store.commit('SET_PUSH_AUTH', data.data.auth)

                console.log('%c Notification subscription updated 🔔', 'color: #883ae8')
            } catch (e) {
                console.error(e, 'userUpdateSubscribe')
            }
        },
        async userSubscribe(reg) {
            try {
                if(this.config?.site_setting?.push_key) {
                    const subscription = await reg.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: this.config.site_setting.push_key
                    })
                    const payload = {
                        subscription,
                        uid: this.user.id,
                        buid: generateDeviceUUID()
                    }
    
                    const {data} = await this.$http2.post('/subscribe/subscribe/', payload)
                    if(data?.data?.auth)
                        this.$store.commit('SET_PUSH_AUTH', data.data.auth)
    
                    console.log('%c User subscribed to notifications 🔔', 'color: #883ae8')
                } else
                    console.log('%c There is no push key in the site settings', 'color: #bf1432')
            } catch (e) {
                console.error(e, 'userSubscribe')
            }
        }
    },
    mounted() {
        eventBus.$on('user_logged', ()=> {
            this.checkNotify()
        })
    },
    beforeDestroy() {
        eventBus.$off('user_logged')
    }
}